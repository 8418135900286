import { useState } from "react";

import { useHistory } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";

import { bombBanner } from "assets/img/homepage/dodge-bomb";

import { getMyPublicIP } from "api/utils";
import { postAuth, postPlay } from "features/api/bomb";
import { useDispatch, useSelector } from "react-redux";
import { alertDialog, alertDialogs, showLoader } from "views/homepage/utils";

// services
import { updateGame } from "actions";

const DodgeGamePage = () => {
  const currentUsername = useSelector((state) => state.entities.user.username);
  const userChips = useSelector((state) => state.entities.user.chips);
  const isLoggedIn = useSelector(
    (state) => state.ui.loginOutcome === "LOGGED_IN"
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const [userIp, setUserIp] = useState("120.29.98.228");

  const initializeDodgeBomb = () => {
    if (!isLoggedIn) {
      alertDialog("Please login to continue", "Okay", "warning");
      return;
    }

    getMyPublicIP().then((result) => {
      setUserIp(result);
    });

    try {
      postAuth({
        userId: currentUsername,
        nickName: currentUsername,
        userIp: userIp,
        balance: userChips,
      }).then((result) => {
        if (result.resultCode !== "0") {
          alertDialogs(
            "Dodge Bomb Auth Failed",
            result.resultMessage,
            "Try again",
            "warning"
          );
          return;
        }

        const loader = showLoader("dodge bombs");

        postPlay({
          userId: currentUsername,
          balance: userChips,
          userIp: userIp,
          token: result.data.token,
        }).then((result) => {
          if (result.resultCode === "0") {
            var gameId = result.data.gameurl.split("?");
            var parsedGameUrl = gameId[gameId.length - 1];

            dispatch(
              updateGame({
                dodgeBombUrl: parsedGameUrl,
              })
            );

            history.push("/game/dodge-bomb");
            loader.close();
            window.location.reload();
          }
        });
      });
    } catch (e) {
      alertDialog("Something went wrong launching game", "Okay", "error");
    }
  };

  return (
    <section>
      <div className="pb-5 text-center mx-1 mx-md-14 mx-lg-5 pt-3 mx-sm-3 mx-xs-2">
        <section className="section-margin-top text-white p-lg-3 p-0">
          <Row className="px-0 mx-0">
            <Col xl={8} sm={12}>
              <img
                className="w-100 h-100"
                alt="..."
                src={bombBanner}
                style={{ objectFit: "cover", borderRadius: "20px" }}
              />
            </Col>
            <Col xl={4} sm={12} className="sm-pl-0 mt-xl-0 mt-3">
              <Card className="bg-dark p-4 text-left">
                <div>
                  <h1 className="text-white text-md">Hero's Dodge Bombs</h1>
                  <h3 className="text-white text-md">
                    Action-packed betting game: Dodge bombs, bet on success!
                  </h3>
                </div>

                <div className="w-100 mt-3 d-flex">
                  <a onClick={initializeDodgeBomb} href="#" className="w-100">
                    <button className="btn-gold mr-auto">
                      <i className="fa fa-play mr-2"></i>
                      PLAY NOW{" "}
                    </button>
                  </a>
                </div>

                <div className="mt-3">NOTE: Game is still in BETA</div>
              </Card>
            </Col>
          </Row>
          <div className="mx-3">
            <Row>
              <Col xl={6} lg={12} md={12} xs={12} className="mt-2">
                <div className="text-left my-3">
                  <h1 className="text-white">How to Play the Bomb Game</h1>
                </div>
                <Card className="bg-dark p-3 p-md-3 text-left">
                  <h2 className="text-white">Objective:</h2>
                  <p>Uncover all non-bomb tiles without hitting any bombs.</p>

                  <h2 className="text-white">Gameplay:</h2>
                  <ul>
                    <li>Click on a tile to reveal its content.</li>
                    <li>
                      If the tile is a number, it shows the number of adjacent
                      bombs.
                    </li>
                    <li>
                      Use the numbers as clues to deduce the location of the
                      bombs.
                    </li>
                    <li>
                      Avoid clicking on any bomb tile; otherwise, the game ends.
                    </li>
                  </ul>

                  <h2 className="text-white">Winning:</h2>
                  <p>Successfully reveal any non-bomb tiles to win the game.</p>

                  <h2 className="text-white">Try Again:</h2>
                  <p>
                    If you hit a bomb, start a new game and improve your
                    strategy.
                  </p>
                </Card>
              </Col>
              <Col xl={6} lg={12} md={12} xs={12} className="mt-2">
                <div className="text-left my-3">
                  <h1 className="text-white">FAQ</h1>
                </div>
                <Card className="bg-dark p-3 p-md-3 text-left">
                  <h2 className="text-white mb-0">Game did not launch?</h2>
                  <h2 className="text-white">
                    Change your default Pop-ups & Redirects settings
                  </h2>
                  <h2 className="text-white">IOS - Chrome</h2>
                  <ol>
                    <li>On your computer, open Chrome.</li>
                    <li>
                      At the top right, click on the <strong>More</strong>{" "}
                      (three vertical dots) button.
                    </li>
                    <li>
                      From the dropdown menu, select <strong>Settings</strong>.
                    </li>
                    <li>
                      Click on <strong>Privacy and security</strong> in the left
                      sidebar.
                    </li>
                    <li>
                      Under <strong>Site Settings</strong>, find and click on{" "}
                      <strong>Pop-ups and redirects</strong>.
                    </li>
                    <li>
                      Choose the option that you want as your default setting.
                    </li>
                  </ol>
                  <h2 className="text-white mb-0">IOS - Safari</h2>
                  <ol>
                    <li>Open a Safari browser window.</li>
                    <li>
                      Select the <strong>“Safari”</strong> menu from the top
                      menu bar.
                    </li>
                    <li>
                      From the drop-down menu, select{" "}
                      <strong>“Preferences”</strong>.
                    </li>
                    <li>
                      The preference pane will open. Select{" "}
                      <strong>“Security”</strong> on the top row.
                    </li>
                    <li>
                      Uncheck the checkbox labeled{" "}
                      <strong>"Block pop-up windows"</strong>.
                    </li>
                    <li>Close the Preferences window.</li>
                  </ol>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </section>
  );
};

export default DodgeGamePage;
