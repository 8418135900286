import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "assets/scss/argon-dashboard-react.scss";
import "assets/css/styles.css";
import "react-data-table-component-extensions/dist/index.css";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import HomeLayout from "layouts/Home.js";
import PlayerLayout from "layouts/Player.js";
import { Provider } from "react-redux";
import store, { Persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from "react-hot-toast";

import DodgeGame from "views/homepage/pages/dodge-bomb/DodgeGame";
import JILIGame from "views/homepage/pages/slot-machine/JILIGame";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={Persistor}>
      <BrowserRouter>
        <Toaster position="bottom" />
        <Switch>
          <Route path="/game/dodge-bomb" component={DodgeGame} />
          <Route path="/game/JILI/:id" component={JILIGame} />

          <Route
            path="/player"
            render={(props) => <PlayerLayout {...props} />}
          />
          <Route
            path="/portal"
            render={(props) => <AdminLayout {...props} />}
          />

          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/" render={(props) => <HomeLayout {...props} />} />

          {/* <Redirect from="*" to="/home" /> */}
        </Switch>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
