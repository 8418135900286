import React from "react";
import { Row, Col, Card, Button } from "reactstrap";

import { ibBanner, vkBanner } from "assets/img/homepage";

// images
import { fire } from "assets/img/homepage";

const AllGames = () => {
  const games = [
    {
      id: "card-1",
      name: "in between",
      description:
        "Player has placed a bet, the dealer turns up the top card and places it between the two cards.",
      image: ibBanner,
      color: "bg-hot-danger",
    },
    {
      id: "card-2",
      name: "video karera",
      description:
        "Bring back memories, the best horse racing betting game on mobile is now here!",
      image: vkBanner,
      color: "bg-hot-dark",
    },
  ];

  return (
    <section>
      <div className="text-left d-flex">
        <img className="text-right mr-2 h-10 w-10" alt="..." src={fire} />
        <h1 className="text-white pt-2">HOT GAMES</h1>
      </div>
      <Row>
        {games.map((index) => {
          return (
            <Col xl={6} lg={12} md={12} key={index.id}>
              <Card className={`${index.color} mt-4`}>
                <div className="hot-game-card">
                  <Row>
                    <Col
                      xl={6}
                      lg={8}
                      md={{ order: "first", size: 7 }}
                      xs={{ order: "last", size: 12 }}
                      className="content"
                    >
                      <h1 className="text-white text-uppercase">
                        {index.name}
                      </h1>
                      <p>{index.description}</p>

                      <a href={process.env.REACT_APP_APP_URL}>
                        <Button className="btn-gold download-app-btn px-4">
                          DOWNLOAD APP
                        </Button>
                      </a>
                    </Col>

                    <div className="mx-auto">
                      <img alt="..." src={index.image} />
                    </div>
                  </Row>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
    </section>
  );
};

export default AllGames;
